import colors from './colors';

export const theme = {
  breakpoints: [576, 768, 991, 1220],
  space: [0, 5, 8, 10, 15, 20, 25, 30, 33, 35, 40, 50, 60, 70, 80, 85, 90, 100],
  fontSizes: [10, 12, 14, 15, 16, 18, 20, 22, 24, 36, 48, 80, 96],
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  borders: [
    0,
    '1px solid',
    '2px solid',
    '3px solid',
    '4px solid',
    '5px solid',
    '6px solid',
  ],
  radius: [3, 4, 5, 10, 20, 30, 60, 120, '50%'],
  widths: [36, 40, 44, 48, 54, 70, 81, 128, 256],
  heights: [36, 40, 44, 46, 48, 54, 70, 81, 128],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors,
  colorStyles: {
    primary: {
      color: colors.primary,
      borderColor: colors.primary,
      '&:hover': {
        color: colors.primaries[6],
        borderColor: colors.primaries[6],
      },
    },
    primaryDark: {
      color: colors.primaries[6],
      borderColor: colors.primaries[6],
      '&:hover': {
        color: colors.primaries[7],
        borderColor: colors.primaries[7],
      },
    },
    success: {
      color: colors.success,
      borderColor: colors.success,
      '&:hover': {
        color: colors.successes[6],
        borderColor: colors.successes[6],
      },
    },
    info: {
      color: colors.info,
      borderColor: colors.info,
      '&:hover': {
        color: colors.infos[6],
        borderColor: colors.infos[6],
      },
    },
    warning: {
      color: colors.warning,
      borderColor: colors.warning,
      '&:hover': {
        color: colors.warnings[6],
        borderColor: colors.warnings[6],
      },
    },
    danger: {
      color: colors.danger,
      borderColor: colors.danger,
      '&:hover': {
        color: colors.dangers[6],
        borderColor: colors.dangers[6],
      },
    },
    white: {
      color: colors.white,
      borderColor: colors.white,
      '&:hover': {
        color: colors.offWhite,
        borderColor: colors.offWhite,
      },
    },
    primaryWithBg: {
      color: colors.white,
      backgroundColor: colors.primary,
      borderColor: colors.primary,
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.primaries[6],
        borderColor: colors.primaries[6],
        boxShadow: colors.primaryBoxShadow,
      },
    },
    successWithBg: {
      color: colors.black,
      backgroundColor: colors.success,
      borderColor: colors.success,
      '&:hover': {
        color: colors.black,
        backgroundColor: colors.successes[6],
        borderColor: colors.successes[6],
        boxShadow: colors.successBoxShadow,
      },
    },
    infoWithBg: {
      color: colors.black,
      backgroundColor: colors.info,
      borderColor: colors.info,
      '&:hover': {
        color: colors.black,
        backgroundColor: colors.infos[6],
        borderColor: colors.infos[6],
        boxShadow: colors.infoBoxShadow,
      },
    },
    warningWithBg: {
      color: colors.black,
      backgroundColor: colors.warning,
      borderColor: colors.warning,
      '&:hover': {
        backgroundColor: colors.warnings[6],
        borderColor: colors.warnings[6],
        boxShadow: colors.warningBoxShadow,
      },
    },
    dangerWithBg: {
      color: colors.white,
      backgroundColor: colors.danger,
      borderColor: colors.danger,
      '&:hover': {
        backgroundColor: colors.dangers[6],
        borderColor: colors.dangers[6],
        boxShadow: colors.dangerBoxShadow,
      },
    },
    transparentBg: {
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: colors.white,
      },
    },
  },
  buttonStyles: {
    textButton: {
      border: 0,
      color: colors.primary,
      padding: 0,
      height: 'auto',
      backgroundColor: colors.transparent,
    },
    outlined: {
      borderWidth: '1px',
      borderStyle: 'solid',
      backgroundColor: colors.transparent,
    },
    fab: {
      border: '0',
      width: '40px',
      height: '40px',
      padding: 0,
      borderRadius: '50%',
      justifyContent: 'center',
      'span.btn-icon': {
        paddingLeft: 0,
      },
    },
    extendedFab: {
      border: '0',
      minWidth: '50px',
      height: '40px',
      borderRadius: '50px',
      justifyContent: 'center',
    },
  },
};
