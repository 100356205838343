import {oneLine} from 'common-tags';
import themeColors from "./custom-theme.json"

const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  offWhite: '#f6f5f5',
  headingColor: '#302b4e',
  textColor: '#43414e',
  labelColor: '#767676',
  inactiveField: '#f2f2f2',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#ebebeb',
  borderColor: '#1b1e25',

  primary: themeColors["color-primary-500"],
  primaries: [
    themeColors["color-primary-100"],
    themeColors["color-primary-200"],
    themeColors["color-primary-300"],
    themeColors["color-primary-400"],
    themeColors["color-primary-500"],
    themeColors["color-primary-600"],
    themeColors["color-primary-700"],
    themeColors["color-primary-800"],
    themeColors["color-primary-900"],
  ],
  primaryTransparents: [
    themeColors["color-primary-transparent-100"],
    themeColors["color-primary-transparent-200"],
    themeColors["color-primary-transparent-300"],
    themeColors["color-primary-transparent-400"],
    themeColors["color-primary-transparent-500"],
    themeColors["color-primary-transparent-600"],
  ],

  success: themeColors["color-success-500"],
  successes: [
    themeColors["color-success-100"],
    themeColors["color-success-200"],
    themeColors["color-success-300"],
    themeColors["color-success-400"],
    themeColors["color-success-500"],
    themeColors["color-success-600"],
    themeColors["color-success-700"],
    themeColors["color-success-800"],
    themeColors["color-success-900"],
  ],
  successTransparents: [
    themeColors["color-success-transparent-100"],
    themeColors["color-success-transparent-200"],
    themeColors["color-success-transparent-300"],
    themeColors["color-success-transparent-400"],
    themeColors["color-success-transparent-500"],
    themeColors["color-success-transparent-600"],
  ],

  info: themeColors["color-info-500"],
  infos: [
    themeColors["color-info-100"],
    themeColors["color-info-200"],
    themeColors["color-info-300"],
    themeColors["color-info-400"],
    themeColors["color-info-500"],
    themeColors["color-info-600"],
    themeColors["color-info-700"],
    themeColors["color-info-800"],
    themeColors["color-info-900"],
  ],
  infoTransparents: [
    themeColors["color-info-transparent-100"],
    themeColors["color-info-transparent-200"],
    themeColors["color-info-transparent-300"],
    themeColors["color-info-transparent-400"],
    themeColors["color-info-transparent-500"],
    themeColors["color-info-transparent-600"],
  ],

  warning: themeColors["color-warning-500"],
  warnings: [
    themeColors["color-warning-100"],
    themeColors["color-warning-200"],
    themeColors["color-warning-300"],
    themeColors["color-warning-400"],
    themeColors["color-warning-500"],
    themeColors["color-warning-600"],
    themeColors["color-warning-700"],
    themeColors["color-warning-800"],
    themeColors["color-warning-900"],
  ],
  warningTransparents: [
    themeColors["color-warning-transparent-100"],
    themeColors["color-warning-transparent-200"],
    themeColors["color-warning-transparent-300"],
    themeColors["color-warning-transparent-400"],
    themeColors["color-warning-transparent-500"],
    themeColors["color-warning-transparent-600"],
  ],

  danger: themeColors["color-danger-500"],
  dangers: [
    themeColors["color-danger-100"],
    themeColors["color-danger-200"],
    themeColors["color-danger-300"],
    themeColors["color-danger-400"],
    themeColors["color-danger-500"],
    themeColors["color-danger-600"],
    themeColors["color-danger-700"],
    themeColors["color-danger-800"],
    themeColors["color-danger-900"],
  ],
  dangerTransparents: [
    themeColors["color-danger-transparent-100"],
    themeColors["color-danger-transparent-200"],
    themeColors["color-danger-transparent-300"],
    themeColors["color-danger-transparent-400"],
    themeColors["color-danger-transparent-500"],
    themeColors["color-danger-transparent-600"],
  ]

};

const shadows = {
  primaryBoxShadow: '0px 8px 20px -6px rgba(50, 207, 255, 0.48)',
  successBoxShadow: '0px 8px 20px -6px rgba(66, 209, 78, 0.48)',
  infoBoxShadow: '0px 8px 20px -6px rgba(43, 156, 255, 0.48)',
  warningBoxShadow: '0px 8px 20px -6px rgba(255, 223, 20, 0.48)',
  dangerBoxShadow: '0px 8px 20px -6px rgba(255, 71, 83, 0.48)',
};

const gradients = {
  primaryGradient: oneLine`
    linear-gradient(
      ${colors.primaries[4]} 0%,
      ${colors.primaries[6]} 100%
    );
  `
};

export default {
  ...colors,
  ...shadows,
  ...gradients
};
