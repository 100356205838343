import { get, is } from "styled-system"

export function themeRequire(path) {
  return function (props) {
    const value = get(props.theme, path, undefined);
    if (!is(value)) {
      throw Error(`Theme value requested but does not exist or has no value: ${path}`);
    }
    return value
  };
}

export function themeApply(path, transform) {
  return function (props) {
    const value = get(props.theme, path, undefined);
    if (!is(value)) {
      throw Error(`Theme value requested but does not exist or has no value: ${path}`);
    }
    return transform(value);
  };
}
